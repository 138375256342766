import $ from 'jquery';
import Pikaday from 'pikaday/pikaday'
import 'select2/dist/js/select2';
import 'way2web.jquery.confirm/js/jquery.confirm';

class Components {
    /**
     * Initialize the date picker.
     */
    static initDatePicker() {
        const element = $(this);

        const options = {
            field: element[0],
            format: element.data('date-format'),
        }

        /**
         * Pikaday is a vanilla JS package, so use the vanilla JS dom element
         * from the jQuery element object.
         */
        new Pikaday(options); // eslint-disable-line no-new
    };

    /**
     * Initialize the confirm function.
     */
    static initConfirm() {
        const confirmElement = $(this);

        // somehow $.fn.confirm is not set correctly
        if ($.fn.confirm == null) {
            $.fn.confirm = jQuery.fn.confirm;
        }

        confirmElement.confirm({
            title: 'Confirmation required'
        });
    };

    /**
     * Initialize the select2 function, for nice select elements.
     */
    static initSelect2() {
        var select = $(this);
        var placeholder = select.data('placeholder');
        var dataUrl = select.data('dataUrl');
        var dataType = select.data('dataType');
        var options = {
            allowClear:  true,
            placeholder: placeholder,
            theme:       'bootstrap',
            width:       null
        };

        if (typeof placeholder === 'undefined' || placeholder === '') {
            throw 'A data-placeholder value is needed on the select2 element';
        }

        if (dataUrl) {
            options.ajax = {
                url:      dataUrl,
                dataType: dataType,
                delay:    250,
                cache:    true,
                data:     function(params) {
                    return {
                        search: params.term
                    };
                }
            };
            options.minimumInputLength = 2;
        }

        select.select2(options);
    };

    /**
     * Toggles form fields based on the selected value.
     *
     * Add the class 'js-select-toggle' to the select, 'js-form-select-field' to a possible toggle field.
     *
     * Add the 'data-select-values=""' data attribute with in it, the value(s) it needs to be visible for.
     * For example: <div class="form group | js-select-toggle" data-select-values='["admin","company-admin"]'>
     */
    static initSelectFieldToggle() {
        const elemSelect = $('.js-select-toggle');
        if (!elemSelect.length) {
            return;
        }

        const possibleFields = $('.js-select-toggle-field');

        const processFields = function() {
            let selectedValue = elemSelect.val();

            possibleFields.hide();

            possibleFields.each(function() {
                let field = $(this);
                let fieldToggleValues = field.data('select-values');

                if (fieldToggleValues.indexOf(selectedValue) !== -1) {
                    field.show();
                }
            });
        };

        elemSelect.on('change', function() {
            processFields();
        });

        processFields();
    };
}

export default Components
