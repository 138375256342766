import $ from 'jquery';
import Editor from '@toast-ui/editor'

class Markdown {
    /**
     * Initialize the Markdown component.
     */
    constructor() {
        this.markdownEditors = [];
    }

    // Find the elements to connect the editor.
    static init() {
        const editor = new Markdown();

        $('.js-markdown').each(editor.initElement.bind(editor));
    }

    /**
     * Attach summernote to the textareas.
     *
     * @param {integer} index
     * @param {object} element
     */
    initElement(index, element) {
        let mdEditor = new Editor({
            el: element,
            height: '400px',
            initialValue: $(element).attr('value'),
            initialEditType: 'markdown',
            usageStatistics: false,
            hideModeSwitch: true,
            toolbarItems: [
                ['bold', 'italic', 'strike'],
                ['ul', 'ol'],
                ['link']
            ],
            viewer: true,
        });

        this.markdownEditors.push(
            mdEditor
        );

        $(element).closest("form").submit(function (event) {
            $('#' + $(element).attr('id').replace('md-div', 'md-input'))
                .val(mdEditor.getMarkdown());
        });
    }
}

export default Markdown;
