import $ from 'jquery';
import DataTable from 'way2web-datatables/dist/datatables';
/* eslint-disable max-len */

/**
 * Add datatables to the page with ajax.
 */
class DataTables {
    /**
     * Initialize the datatables component.
     */
    constructor() {
        this.elements = {
            tableSelector: '.js-datatable',
        };

        $(this.elements.tableSelector).each(this.find.bind(this));
    }

    /**
     * Attach the DataTable core to the elements.
     *
     * @param {integer} index
     * @param {object} element
     */
    find(index, element) {
        const item = new DataTable(
            $(element),
            {
                language: 'en',
                dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 text-right"B>>trlip<"clear">',
                serverSide: false,
            },
            {},
            this.translations()
        );

        item.init();
    }

    /**
     * The translations object for the Datatables package.
     *
     * @return {object}
     */
    translations() {
        return {
            /**
             * Get all the datatables translations with Lang.js.
             *
             * @return {object}
             */
            get() {
                return {
                    oAria: {
                        sSortAscending:  ': activate to sort column ascending',
                        sSortDescending:': activate to sort column descending',
                    },
                    oPaginate: {
                        sFirst:    'First',
                        sLast:     'Last',
                        sNext:     'Next',
                        sPrevious: 'Previous',
                    },
                    sEmptyTable:     'No data available in table',
                    sInfo:           'Showing _START_ to _END_ of _TOTAL_ entries',
                    sInfoEmpty:      'Showing 0 to 0 of 0 entries',
                    sInfoFiltered:   '(filtered from _MAX_ total entries)',
                    sInfoPostFix:    '',
                    sInfoThousands:  ',',
                    sLengthMenu:     'Show _MENU_ entries',
                    sLoadingRecords: 'Loading...',
                    sProcessing:     'Processing...',
                    sSearch:         '',
                    sZeroRecords:    'No matching records found',
                    all:            'All',
                    columns:         'Columns',
                };
            },
        };
    };
}

export default DataTables
