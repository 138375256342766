class Config {

    /**
     * Settings for the JavaScript functions.
     *
     * @type {object}
     */
    static init() {
        this.config = {
            classes: {
                success: 'has-success',
                error:   'has-error',
                hidden:  'hidden'
            },
            tabPrefix: 'w2w_tab_'
        };
    }
}

export default Config
