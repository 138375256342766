import $ from 'jquery';

import 'bootstrap-sass/assets/javascripts/bootstrap.min';
import Components from './scripts/components';
import Config from './scripts/config';
import DataTables from './scripts/datatables';
import W2Form from 'way2web-form';
import Tabs from './scripts/tabs';
import Url from './scripts/url';
import Editor from './scripts/editor';
import Markdown from './scripts/markdown';
import Lead from './scripts/lead';

require('./styles/main.scss');

/**
 *  Bootstrap the application when the jQuery ready event is fired.
 */
class Way2web {
    /**
     * Call function if the dom is ready.
     */
    static onDomReady() {
        const token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token,
            },
        });

        new DataTables(); // eslint-disable-line no-new
        new Tabs(); // eslint-disable-line no-new

        Config.init();
        Editor.init();
        Markdown.init();

        $('.js-select2').each(Components.initSelect2);
        $('.js-datepicker').each(Components.initDatePicker);
        $('.js-confirm').each(Components.initConfirm);
        $('.js-select-toggle').each(Components.initSelectFieldToggle);

        const url = new Url();
        url.init();

        const form = new W2Form();
        form.init(document);

        const lead = new Lead();
        lead.init();
    }
}

$(document).ready(Way2web.onDomReady);
