import $ from 'jquery';
import 'select2/dist/js/select2';

/* eslint-disable max-len */
class Url {
    /**
     * Initialize the url component.
     */
    constructor() {
        // Array with the elements for the url.
        this.elements = {
            urlTable: '.js-url-table',
            urlCreate: '.js-url-create',
            urlVisit: '.js-url-visit',
            urlDestroy: '.js-url-destroy',
            input: '.js-input',
        };
    }

    /**
     * Initialize the Url event handlers only once.
     */
    init() {
        $(this.elements.urlTable).on('click', this.elements.urlVisit, this.visitUrl);
        $(this.elements.urlTable).on('click', this.elements.urlDestroy, this.destroyUrl);
        $(this.elements.urlTable).on('change', this.elements.input, this.updateUrl);
        $(this.elements.urlCreate).on('click', this.addUrl).bind(this);
        $(this.elements.urlTable).on('keypress', 'input', this.updateOnEnter);
    }

    visitUrl() {
        const form = $(this).closest('tr').find('form');
        const inputElement = form.find('.js-input').first();
        const url = inputElement.val();

        window.open(url);
    };

    destroyUrl(event) {
        let element = $(this);
        let message = element.data('confirm-message');
        let href = element.attr('href');
        let tableRow = element.closest('tr');
        let url = new Url();

        jQuery.confirm({
            text:    message,
            confirm() {
                return $.ajax({
                    url:      href,
                    type:     'delete',
                    dataType: 'json',
                    success(response) {
                        tableRow.fadeOut('normal', function() {
                            tableRow.remove();
                        });
                        url.setAlertElement(response.message, 'alert-success');
                    }
                });
            }
        });
        event.preventDefault();

        return false;
    };

    updateUrl(event) {
        let form = $(this).closest('form');
        let href = form.data('href');
        let data = form.serializeArray();
        let alertClassName;
        let url = new Url();

        $.ajax({
            url:      href,
            type:     'put',
            data:     data,
            dataType: 'json',
            success(response) {
                if (response.result === 'success') {
                    alertClassName = 'alert-success';
                } else {
                    alertClassName = 'alert-danger';
                }
                url.setAlertElement(response.message, alertClassName);
            }
        });
        event.preventDefault();
    };

    addUrl(event) {
        let href = $(this).attr('href');
        let table = $('.js-url-table');
        let alertClassName;
        let data = {
            environmentName: $('.js-environment-select').val(),
            serverId:        table.val()
        };
        let url = new Url();

        $.ajax({
            url:      href,
            type:     'post',
            data:     data,
            dataType: 'json',
            success(response) {
                let select;
                let placeholder;

                if (response.result === 'success') {
                    $(response.data)
                        .hide()
                        .prependTo(table)
                        .fadeIn(500);
                    alertClassName = 'alert-success';

                    select = $('.js-server-select').first();
                    placeholder = select.data('placeholder');

                    if (typeof placeholder === 'undefined' || placeholder === '') {
                        throw 'A data-placeholder value is needed on the select2 element';
                    }

                    select.select2({
                        allowClear:  true,
                        placeholder: placeholder,
                        theme:       'bootstrap',
                        width:       null
                    });
                } else {
                    alertClassName = 'alert-danger';
                }
                url.setAlertElement(response.message, alertClassName);
            }
        });
        event.preventDefault();
    };

    updateOnEnter(event) {
        if (event.key === 'Enter') {
            this.blur();
            event.preventDefault();
        }
    };

    setAlertElement(message, className) {
        let url = new Url();
        let alertElement = $('.js-alert');

        if (alertElement.data('visible')) {
            alertElement.fadeOut('normal', function() {
                url.setAlertElementContents(alertElement, message, className);
                alertElement.fadeIn();
            });
        } else {
            url.setAlertElementContents(alertElement, message, className);
        }
        alertElement.data('visible', true);
    };

    setAlertElementContents(alertElement, message, className) {
        alertElement[0].innerHTML = message;
        alertElement.removeClass('alert-danger');
        alertElement.removeClass('alert-success');
        alertElement.removeClass('hidden');
        alertElement.addClass(className);
    };
}

export default Url
