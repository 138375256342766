import $ from 'jquery';

class Lead {
    /**
     * Initialize the url component.
     */
     constructor() {
        // Array with the elements for the url.
        this.elements = {
            leadDelete: '#lead-delete',
        };
    }

    /**
     * Initialize the Url event handlers only once.
     */
    init() {
        $(this.elements.leadDelete).on('click', this.deleteLead).bind(this);
    }

    deleteLead(event) {
        let href = $(this).data('route');
        let redirectUrl = $(this).data('redirect');

        $.ajax({
            url: href,
            type: 'DELETE',
            success: function() {
                window.location = redirectUrl;
            },
            error: function() {
                alert('Error, something went wrong while deleting this item');
            }
        });
        event.preventDefault();
    }
}

export default Lead
